import axios from 'axios';

const VOALLE_TOKEN = 'nrPgDMir0Jd20blEAtA2GhaEDL5VMo7y8llssXqswKP2Hqf5vt';

const api = axios.create({
  baseURL: "https://dev.rbt.psi.br:5012",
  headers: {
    'x-access-token': VOALLE_TOKEN
  }
})

export default api;