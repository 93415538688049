import React from 'react';

import './styles.css';
import logo from '../../assets/logo.png';

export default function Header() {
  return (
    <nav className="header-container">
      <img src={logo} alt="" />
    </nav>
  );
}