import React from 'react';
import './styles.css';

const GOOGLE_API_KEY = 'AIzaSyBuR5bLKcoYNdy36YDiKJ2BFOkRpGvCJVw'

export default function Map({ lat, lng }) {
  return (
    <iframe
      className="map"
      src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${lat},${lng}`}
      allowFullScreen
      referrerPolicy="no-referrer-when-downgrade"
      title="map"
    />
  )
}

