import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Site from './pages/Site';

export default function Routes2() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/site/:curSite/' element={<Site />} />
      </Routes>
    </BrowserRouter>
  );
}